import { auth } from '~/plugins/firebase'

export default function ({ store, redirect, route }) {
  auth.onAuthStateChanged(async (firebaseUser) => {
    if (firebaseUser && !store.state.auth.signing) {
      const user = await store.dispatch('auth/setFirebaseUser', firebaseUser)
      if (!user.furigana && route.path !== '/signup') {
        redirect('/signup')
      }
    }
  })
}
